import classes from "../SignIn/SignIn.module.css";
import { useState } from "react";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import Input from "../../../Components/Input/Input";
import ErrorAlert from "../../../Components/Error/Error";
import { capitalize } from "../../../HelperFunctions/capitalize";
import Button from "../../../Components/Button/Button";
import PasswordStrength from "../../Components/PasswordStrength/PasswordStrength";
import { useNavigate, useSearchParams } from "react-router-dom";
import errors from "Utilities/errors";
import { resetPassword } from "Services";

const ResetPassword = () => {
  // Router
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const resetPasswordToken = searchParams.get("passwordResetToken");

  //   States
  const [passwordIsvalid, setPasswordIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Utils

  const isSamePassword = () => password === confirmPassword;

  const handleResetPassword = async () => {
    try {
      if (!resetPasswordToken)
        throw new Error("reset password token is missing");

      setIsSubmitting(true);

      await resetPassword({ token: resetPasswordToken, newPassword: password });

      setSuccessMessage("Successfully reset password");

      setTimeout(() => {
        setErrorMessage("");

        navigate("/sign-in");
      }, 1000);
    } catch (err) {
      const errMessage = errors.format(err);

      setErrorMessage(errMessage);

      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.feedback}>
          {successMessage && (
            <ErrorAlert type="success">{capitalize(successMessage)}</ErrorAlert>
          )}

          {errorMessage && (
            <ErrorAlert type="error">{capitalize(errorMessage)}</ErrorAlert>
          )}
        </div>

        <div className={classes.header}>
          <h4>Reset password</h4>

          <p>
            Enter a new password you’ll remember. We’ll ask for this password
            whenever you want to log in
          </p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            label="Enter new password"
            isRequired
            placeholder="Enter Password"
            tip="Passwords must be at least 8 characters "
            type="password"
            name="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />

          {password && (
            <PasswordStrength
              password={password}
              setPasswordConditionsValid={setPasswordIsValid}
            />
          )}

          <span>
            <Input
              label="Confirm new password"
              isRequired
              type="password"
              placeholder="Enter Password"
              errorMessage="Passwords do not match"
              condition={isSamePassword()}
              name="confirmPassword"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
          </span>

          <div className={classes.buttonSection}>
            <Button
              onClick={handleResetPassword}
              loading={isSubmitting}
              disabled={
                !passwordIsvalid ||
                !isSamePassword() ||
                !password ||
                !confirmPassword
              }
            >
              Update password
            </Button>
          </div>
        </form>
      </section>
    </AuthLayout>
  );
};

export default ResetPassword;
