import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import classes from "./SignIn.module.css";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import { useAuth } from "../../../Context/AuthUserContext";
import Error from "../../../Components/Error/Error";
import Input from "../../../Components/Input/Input";
import { capitalize } from "../../../HelperFunctions/capitalize";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Google from "../../Assets/SVGs/Google";
import { authenticateWithGoogle, login } from "Services";
import errors from "Utilities/errors";
import { setToken, TokenKeys } from "v2/Utilities/token";

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser: setAuthUser } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");

  // Utils
  const changeHandler = (e: any) => {
    setUser((prevState) => {
      return { ...prevState, [e?.target?.name]: e.target?.value };
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setIsSubmitting(true);

      authenticateWithGoogle({ token: tokenResponse.access_token })
        .then((res) => {
          setToken(TokenKeys.ACCESS_TOKEN, res.data.accessToken);
          setToken(TokenKeys.REFRESH_TOKEN, res.data.refreshToken);

          if (!res.data.student.onboarding_complete) {
            return navigate("/onboarding");
          }

          setAuthUser((state) => ({ ...state, data: res.data.student }));
          navigate("/dashboard");
        })
        .catch((error) => {
          const errMessage = errors.format(error);

          setErrorMessage(errMessage);

          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        })
        .finally(() => setIsSubmitting(false));
    },
  });

  const handleLogin = async () => {
    try {
      setIsSubmitting(true);

      const { data: loginResponse } = await login(user);

      if (loginResponse.responseMessage === "Email Verification Required!") {
        return navigate(`/verify-email?${user.email}`);
      }

      setToken(TokenKeys.ACCESS_TOKEN, loginResponse.accessToken);
      setToken(TokenKeys.REFRESH_TOKEN, loginResponse.refreshToken);

      if (!loginResponse.student.onboarding_complete) {
        return navigate("/onboarding?step=1");
      }

      setAuthUser((state) => ({ ...state, data: loginResponse.student }));
      navigate("/dashboard");
    } catch (err) {
      const errMessage = errors.format(err);

      setErrorMessage(errMessage);

      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.header}>
          <h4>Welcome back</h4>
          <p>Log in to continue your learning journey.</p>
        </div>

        <Button type="bordered" onClick={() => handleGoogleLogin()}>
          <Google />
          <span>Continue with Google</span>
        </Button>

        <div className={classes.continueWith}>
          <hr />
          <span>or continue with your email</span>
          <hr />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {errorMessage && (
            <Error type="error">{capitalize(errorMessage)}</Error>
          )}

          <Input
            label="Email"
            isRequired
            placeholder="name@gmail.com"
            value={user.email}
            onChange={changeHandler}
            name="email"
          />
          <span>
            <Input
              label="Password"
              isRequired
              type="password"
              placeholder="************"
              name="password"
              onChange={changeHandler}
              value={user.password}
            />
          </span>

          <div className={classes.rememberAndForgot}>
            {/* <div>
              <input
                type="checkbox"
                id="remember"
                checked={userLoginInfo.remember}
                name="remember"
                onChange={changeHandler2}
              />
              <span>Remember Me</span>
            </div> */}
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <div className={classes.buttonSection}>
            <Button onClick={handleLogin} loading={isSubmitting}>
              Log in
            </Button>
          </div>
        </form>

        <div className={classes.alternate}>
          Don' t have an account? <Link to="/sign-up">Sign up</Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default SignIn;
