import UserOnboardingLayout from "v2/Components/UserOnboardingLayout/UserOnboardingLayout";
import classes from "../EligibilityTechnical/EligibilityTechnical.module.css";
import Button from "../../Components/Button/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { useContext } from "react";
import Loader from "../../Components/Loader/Loader";

const EligibilityBackground = () => {
  // Navigate
  const navigate = useNavigate();
  const { courseId } = useParams();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  // Context
  const {
    getEligibilityQuestionsrequest,
    setEligibleAnswers,
    eligibleAnswers,
  } = useContext(AuthUserContext);

  return (
    <UserOnboardingLayout>
      {getEligibilityQuestionsrequest.isLoading &&
      !getEligibilityQuestionsrequest.data ? (
        <Loader />
      ) : !getEligibilityQuestionsrequest?.isLoading &&
        (getEligibilityQuestionsrequest?.data as any[]) ? (
        <section className={classes.container}>
          <p className={classes.header}>
            {(getEligibilityQuestionsrequest?.data as any[])[0].title?.replace(
              "?",
              ""
            )}
          </p>
          {(
            getEligibilityQuestionsrequest?.data as any[]
          )[0].eligbility_questions?.map((data: any) => {
            return (
              <div className={classes.questionSection} key={data.id}>
                <p>{data?.text}</p>
                <div className={classes.options}>
                  {data?.options &&
                    data?.options.map((datum: any) => {
                      return (
                        <div key={datum.id}>
                          <input
                            type="radio"
                            name={`question_${data.id}`}
                            checked={
                              eligibleAnswers.eligibility_payload.find(
                                (question: any) =>
                                  question.question_id === data.id
                              )?.value === datum.value
                            }
                            id={datum.id}
                            onChange={() => {
                              setEligibleAnswers((prevState: any) => {
                                const existingQuestion =
                                  prevState.eligibility_payload.find(
                                    (question: any) =>
                                      question.question_id === data.id
                                  );

                                if (existingQuestion) {
                                  // Question exists, update the value
                                  const updatedEligibilityPayload =
                                    prevState.eligibility_payload.map(
                                      (question: any) =>
                                        question.question_id === data.id
                                          ? { ...question, value: datum.value }
                                          : question
                                    );

                                  return {
                                    eligibility_payload:
                                      updatedEligibilityPayload,
                                  };
                                } else {
                                  const newQuestion = {
                                    question_id: data.id,
                                    value: datum.value,
                                  };

                                  return {
                                    eligibility_payload: [
                                      ...prevState.eligibility_payload,
                                      newQuestion,
                                    ],
                                  };
                                }
                              });
                            }}
                          />
                          <label htmlFor={datum.id}>{datum.text}</label>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}

          <div className={classes.buttonSection}>
            <Button
              type="secondary"
              onClick={() => {
                navigate(`/eligibility/${courseId}`);
              }}
            >
              <span className={classes.buttonInner}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 16L3 12M3 12L7 8M3 12L21 12"
                    stroke="#664EFE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Previous</span>
              </span>
            </Button>
            <Button
              onClick={() => {
                setSearchParams({ step: "2" });
              }}
            >
              <span className={classes.buttonInner}>
                <span>Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M17.5 8L21.5 12M21.5 12L17.5 16M21.5 12L3.5 12"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Button>
          </div>
        </section>
      ) : (
        <div className={classes.noQuestions}>No questions available</div>
      )}
    </UserOnboardingLayout>
  );
};

export default EligibilityBackground;
