import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import ErrorAlert from "../../../Components/Error/Error";
import { capitalize } from "../../../HelperFunctions/capitalize";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import classes from "../SignIn/SignIn.module.css";
import subClasses from "./VerifyEmail.module.css";
import success from "../../../Assets/Gifs/success.gif";
import { resendVerification, verifyEmail } from "Services";
import errors from "Utilities/errors";
import { setToken, TokenKeys } from "v2/Utilities/token";

const VerifyEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const verificationToken = searchParams.get("verificationToken");
  const isVerified = searchParams.get("isVerified");
  const email = searchParams.get("email");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (verificationToken) {
      setIsSubmitting(true);
      verifyEmail({ token: verificationToken })
        .then((res) => {
          setToken(TokenKeys.ACCESS_TOKEN, res.data.accessToken);
          setToken(TokenKeys.REFRESH_TOKEN, res.data.refreshToken);

          setSuccessMessage("Successfully verified user");
          setSearchParams((state) => {
            state.set("isVerified", "true");
            return state;
          });

          setTimeout(() => {
            navigate("/onboarding?step=1");
          }, 3000);
        })
        .catch((err) => {
          const errMessage = errors.format(err);

          setErrorMessage(errMessage);

          setTimeout(() => {
            if (errMessage === "User already verified") {
              navigate("/onboarding?step=1");
            } else {
              setErrorMessage("");
            }
          }, 3000);
        })
        .finally(() => {
          setIsSubmitting(false);

          setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
          }, 5000);
        });
    }

    // eslint-disable-next-line
  }, []);

  const handleResendVerification = async () => {
    try {
      setIsSubmitting(true);

      if (!email) throw new Error("email missing");

      await resendVerification(email);

      setSuccessMessage("Successfully resent verification mail");
    } catch (err) {
      const errMessage = errors.format(err);

      setErrorMessage(errMessage);
    } finally {
      setIsSubmitting(false);

      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
    }
  };

  return (
    <AuthLayout>
      <section className={classes.container}>
        {isVerified === "false" || !isVerified ? (
          <>
            <div className={subClasses.feedback}>
              {successMessage && (
                <ErrorAlert type="success">
                  {capitalize(successMessage)}
                </ErrorAlert>
              )}

              {errorMessage && (
                <ErrorAlert type="error">{capitalize(errorMessage)}</ErrorAlert>
              )}
            </div>

            <div className={classes.header}>
              <img src={success} alt="Success" />
              <h4>Verify your email address</h4>
              <p>
                We sent a verification email to [{email}]. Please check your
                inbox. Click the verification link in the mail to activate your
                account.
              </p>
            </div>

            <Button
              onClick={() => (window.location.href = "mailto:")}
              loading={isSubmitting}
            >
              Open email app
            </Button>

            <p>Didn't receive the email? Check your spam or junk folder</p>
            <p>
              Still can’t find the email?{" "}
              <span onClick={handleResendVerification}>
                Resend verification email
              </span>
            </p>
          </>
        ) : (
          <div className={classes.innerContainer}>
            <div className={classes.header}>
              <img src={success} alt="Success" />

              <h4>Email verified</h4>
              <p>
                Congratulations on verifying your email! Start your learning
                journey with iṣẹ́ School.
              </p>
            </div>
            <div className={classes.buttonSection}>
              <Button
                onClick={() => {
                  navigate("/onboarding?step=1");
                }}
              >
                Start learning
              </Button>
            </div>
          </div>
        )}
      </section>
    </AuthLayout>
  );
};

export default VerifyEmail;
