import axios from "axios";
import endpoints from "./endpoints";

type TNullable<T> = T | null;

export type TUser = {
  id?: number;
  created_at?: TNullable<string>;
  update_at?: TNullable<string>;
  deleted_at?: TNullable<string>;
  first_name: TNullable<string>;
  last_name: TNullable<string>;
  profile_image: TNullable<string>;
  gender: TNullable<string>;
  is_default_password?: boolean;
  onboarding_complete?: boolean;
  phone_number?: TNullable<string>;
  email?: TNullable<string>;
  linkedIn_profile: TNullable<string>;
  twitter_profile?: TNullable<string>;
  bio: TNullable<string>;
  headline?: TNullable<string>;
  country: TNullable<string>;
  state: TNullable<string>;
  dob?: TNullable<string>;
  specialization?: TNullable<string>;
  years_of_experience?: TNullable<string>;
  tech_proficiency?: TNullable<string>;
  certifications?: TNullable<string>;
  email_changed_at?: TNullable<string>;
  status?: TNullable<string>;
  deactivationReason?: TNullable<string>;
  employement_status?: TNullable<string>;
  education?: TNullable<string>;
};

const baseURL = process.env.REACT_APP_API_URL + "/api/ise/v1";

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const iseAccessToken = localStorage.getItem("iseAccessToken");
    request.headers.Authorization = `Bearer ${iseAccessToken}`;
    return request;
  },
  (error) => {
    return error;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export const completeProfile = (payload: any) => {
  return axiosInstance.post(endpoints.ONBOARDING, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getProfile = (url: string) => {
  return axiosInstance.get<TUser>(url).then((res) => res.data);
};

export const updateContactInfo = (payload: any) => {
  return axiosInstance.patch(endpoints.UPDATE_CONTACT_INFO, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

type TChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const changePassword = (payload: TChangePasswordPayload) => {
  return axiosInstance.post(endpoints.CHANGE_PASSWORD, payload);
};

type TChangeEmailPayload = {
  email: string;
  password: string;
};

export const changeEmail = (payload: TChangeEmailPayload) => {
  return axiosInstance.post(endpoints.CHANGE_EMAIL, payload);
};

export const closeAccount = () => {
  return axiosInstance.get(endpoints.CLOSE_ACCOUNT);
};

type TAuthResponse = {
  student: TUser;
  accessToken: string;
  refreshToken: string;
};

export const authenticateWithGoogle = (payload: { token: string }) => {
  return axiosInstance.post<TAuthResponse>(
    endpoints.AUTHENTICATE_WITH_GOOGLE,
    payload
  );
};

export const login = (payload: { email: string; password: string }) => {
  return axiosInstance.post<{
    responseMessage: string;
    accessToken: string;
    refreshToken: string;
    student: TUser;
  }>(endpoints.LOGIN, payload);
};

export const signUp = (payload: { email: string; password: string }) => {
  return axiosInstance.post<string>(endpoints.SIGN_UP, payload);
};

export const resendVerification = (email: string) => {
  return axiosInstance.post<string>(endpoints.RESEND_VERIFICATION(email));
};

export const verifyEmail = (payload: { token: string }) => {
  return axiosInstance.post<{ accessToken: string; refreshToken: string }>(
    endpoints.VERIFY_EMAIL,
    payload
  );
};

export const sendPasswordResetToken = (payload: { email: string }) => {
  return axiosInstance.post<string>(
    endpoints.SEND_RESET_PASSWORD_TOKEN,
    payload
  );
};

export const resetPassword = (payload: {
  token: string;
  newPassword: string;
}) => {
  return axiosInstance.post<string>(endpoints.RESET_PASSWORD, payload);
};

export const getStudent = () => {
  return axiosInstance.get<TAuthResponse>(endpoints.GET_STUDENT);
};
