import Button from "../../Components/Button/Button";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "./SupportFaqs.module.css";

const SupportFaqs = () => {
  const faqs = [
    {
      question: "How do I enrol in a course on iṣẹ́ School?",
      answer: `To enrol, go to the "Course page", explore, and pick a course you like. You are registered into the school once you create an account and make payment.`,
    },

    {
      question: "Can I track my progress in a course?",
      answer: `Yes. Your student dashboard has a tracker that clearly shows your progress and how well you perform alongside your peers.`,
    },
    {
      question: "What should I do if I forgot my password?",
      answer: `Use the "reset password" option on the login screen. We'll send you a link via email to change your password. Follow the directions in the mail to reset your password.`,
    },
    {
      question: "What support is available if I need help with course content?",
      answer: `We have made it easy for you to communicate with your tutor and schedule a one-on-one call to get all the help you need. Go to the "Schedule" option on your dashboard.`,
    },
    {
      question: "How can I access my course materials and assignments?",
      answer: `Your student dashboard has details of your courses and assignments. Once you create an account, your student dashboard is automatically created. `,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <h4>Explore Frequently asked questions</h4>
        <div>
          {faqs.map((data, i) => {
            return (
              <div key={i} className={classes.faq}>
                <FAQDropdown question={data.question} answer={data.answer} />
              </div>
            );
          })}
        </div>

        <div className={classes.viewMore}>
          <Button type="null">View more</Button>
        </div>
      </div>
    </div>
  );
};

export default SupportFaqs;
