import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import SupportCantFind from "../SupportCantFind/SupportCantFind";
import SupportFaqs from "../SupportFaqs/SupportFaqs";
import SupportVisitOurCommunity from "../SupportVisitOurCommunity/SupportVisitOurCommunity";
import classes from "./SupportContainer.module.css";

const SupportContainer = () => {
  // COntext
  const { user } = useContext(AuthUserContext);
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        Hi {user?.data?.first_name || "user"}, how can we help?
      </div>

      <SupportFaqs />
      <SupportVisitOurCommunity />
      <SupportCantFind />
    </section>
  );
};

export default SupportContainer;
