const endpoints = {
  GET_PROFILE: "/students/profile",
  GET_STUDENT: "/students",
  UPDATE_CONTACT_INFO: "/students/profile/contact-info",
  CHANGE_PASSWORD: "/students/password/change",
  ONBOARDING: "/students/onboarding",
  CHANGE_EMAIL: "/students/update-email",
  CLOSE_ACCOUNT: "/students/close-account",
  AUTHENTICATE_WITH_GOOGLE: "/auth/login/google",
  LOGIN: "/auth/students/login",
  SIGN_UP: "/auth/signup",
  RESEND_VERIFICATION: (email: string) =>
    `/auth/student/resend-verification/${email}`,
  VERIFY_EMAIL: "/auth/student/verify",
  SEND_RESET_PASSWORD_TOKEN: "/auth/student/resetPasswordToken",
  RESET_PASSWORD: "/auth/student/resetPassword",
};

export default endpoints;
