import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Error from "../../../Components/Error/Error";
import Input from "../../../Components/Input/Input";
import BackArrow from "../../Assets/SVGs/BackArrow";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import { emaailRegex } from "../../Utilities/constants";
import classes from "../SignIn/SignIn.module.css";
import errors from "Utilities/errors";
import { sendPasswordResetToken } from "Services";

const ForgotPassword = () => {
  // Context
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSendPasswordResetToken = async () => {
    try {
      setIsSubmitting(true);

      await sendPasswordResetToken({ email });

      navigate(`/forgot-password/check-email?email=${email}`);
    } catch (err) {
      const errMessage = errors.format(err);

      setErrorMessage(errMessage);

      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout isAlt>
      <section className={classes.container}>
        <div className={classes.header}>
          <h4>Forgot password</h4>
          <p>
            Enter the email you used to sign up on iṣẹ́ EdTech and we'll send you
            password reset instructions.
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {errorMessage && <Error type="error">{errorMessage}</Error>}

          <Input
            label="Email Address"
            isRequired
            placeholder="Enter email address"
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            name="email"
            condition={emaailRegex.test(email)}
            errorMessage="Enter a valid email address"
          />

          <div className={classes.buttonSection}>
            <Button
              onClick={handleSendPasswordResetToken}
              loading={isSubmitting}
              disabled={!email || !emaailRegex.test(email)}
            >
              Send instructions
            </Button>
          </div>
        </form>

        <div className={classes.alternate}>
          <BackArrow />
          <Link to="/sign-in">Back to Log in</Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default ForgotPassword;
