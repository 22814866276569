import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import classes from "../SignIn/SignIn.module.css";
import success from "../../../Assets/Gifs/success.gif";
import Button from "../../../Components/Button/Button";

import { sendPasswordResetToken } from "Services";
import errors from "Utilities/errors";
import { useApp } from "Context/AppContext";

const ResetPasswordCheckEmail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const { setNotifications } = useApp();

  const email = searchParams.get("email") || "";

  const handleSendPasswordResetToken = async () => {
    try {
      setIsSubmitting(true);

      if (!email) throw new Error("Email is missing");

      await sendPasswordResetToken({ email });

      setNotifications([
        {
          id: Date.now(),
          severity: "success",
          title: "Successfully sent instruction to your mailbox",
        },
      ]);
    } catch (err) {
      const errMessage = errors.format(err);

      setNotifications([
        { id: Date.now(), severity: "error", title: errMessage },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.header}>
          <img src={success} alt="Success" />
          <h4>Check your email</h4>
          <p>
            We just sent you a password reset email, click on the link to reset
            your password
          </p>
        </div>

        <Button
          onClick={() => (window.location.href = "mailto:")}
          loading={isSubmitting}
        >
          Open email app
        </Button>

        <p>Didn't receive the email? Check your spam or junk folder</p>

        <p>
          Still can’t find the email?{" "}
          <span onClick={handleSendPasswordResetToken}>Resend email</span>
        </p>

        <div className={classes.continueWith}>
          <hr />
          <span>or </span>
          <hr />
        </div>

        <div
          className={classes.alternate}
          style={{
            textAlign: "left",
            justifyContent: "flex-start",
          }}
        >
          Remember your password? <Link to="/sign-in">Back to login</Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default ResetPasswordCheckEmail;
